import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { COLORS } from '../styles/theme'

const dialogOverlayId = 'hall-overlay'

const Overlay = styled.div`
  z-index: 2000; /* bootstrap nav has 1030 */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  .box-dialog {
    border-radius: 3px;
    height: 90%;
    width: 90%;
    max-height: 250px;
    max-width: 600px;
    background: rgba(255, 255, 255, 0.7);
    text-align: center;
    padding: 10px 20px 10px 0px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    p {
      padding-left: 1em;
      font-weight: 700;
      color: ${COLORS.text};
    }

    a {
      display: block;
      font-size: 1.9em;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 2px;
      color: ${COLORS.cyan};
      text-decoration: none;
      margin-top: 5px;

      span {
        margin-right: 8px;
        visibility: hidden;
      }

      &:hover {
        color: ${COLORS.blue};
        span {
          visibility: visible;
        }
      }
    }
  }
`

export default function HallOverlay({ overlay, hideOverlay }) {
  if (!overlay) return null

  return (
    <Overlay
      onClick={(e) => {
        if (e.target.id === dialogOverlayId) {
          hideOverlay()
        }
      }}
      id={dialogOverlayId}
    >
      {overlay === 'hall' ? (
        <div className="box-dialog">
          <p>Select 3D Exhibition Hall:</p>
          <Link to="/virtual-exhibition-a">
            <span>→</span>Exhibition Hall A
          </Link>
          <Link to="/virtual-exhibition-b">
            <span>→</span>Exhibition Hall B
          </Link>
        </div>
      ) : (
        <div className="box-dialog">
          <p>Select live conference hall:</p>
          <Link to="/conference-hall-1">
            <span>→</span>Vimeo Conference Hall
          </Link>
          <Link to="/conference-hall-2">
            <span>→</span>Zoom Conference Hall
          </Link>
        </div>
      )}
    </Overlay>
  )
}
