import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Row, Col } from 'react-bootstrap'
import { COLORS } from '../styles/theme'
import LoadIcon from '../components/LoadIcon'

import boxOpen from '../images/admin/box-open.png'
import boxClose from '../images/admin/box-close.png'
import bannerVid from '../images/admin/luckydraw.mp4'
import Axios from 'axios'
import config from '../config'

const Page = styled.div`
  background: ${COLORS.primaryDark};
  background: linear-gradient(
    232deg,
    ${COLORS.primaryDark} 0%,
    ${COLORS.primaryComp} 100%
  );
  min-height: 100vh;
  padding: 5vh 5vw;

  header {
    background: ${COLORS.primary};
    display: flex;
    align-items: center;
    /* padding: 2%; */
    margin-bottom: 3em;

    .vid-wrapper {
      width: 450px;
      position: relative;
      background: blue;
      align-self: stretch;
      video {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        overflow: hidden;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .logo {
      height: 100%;
      padding: 2% 4%;
    }

    .title {
      flex: 1;
      text-align: center;
      padding: 0.5em;
      color: white;

      h2 {
        font-size: 2.3em;
      }

      h1 {
        font-weight: bold;
        font-size: 3em;
      }
    }
  }

  section {
    min-height: 200px;

    p {
      margin: 0;
      font-weight: bold;
      letter-spacing: 1px;
      text-align: center;
    }

    .box-wrapper {
      cursor: pointer;
      &.unopened:hover {
        img {
          animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
          transform: translate3d(0, 0, 0);
          backface-visibility: hidden;
          perspective: 1000px;
        }
      }
      .winner-name {
        position: absolute;
        color: black;
        top: 40%;
        left: 50%;
        transform: translateX(-50%);
        font-size: 1.3vw;
      }

      img {
        display: block;
        margin: auto;
        width: 100%;
      }
    }

    .amount {
      color: white;
      margin-top: 10px;
      font-size: 2em;
    }
  }
`

function getRandom(arr, n) {
  var result = new Array(n),
    len = arr.length,
    taken = new Array(len)
  if (n > len)
    throw new RangeError('getRandom: more elements taken than available')
  while (n--) {
    var x = Math.floor(Math.random() * len)
    result[n] = arr[x in taken ? taken[x] : x]
    taken[x] = --len in taken ? taken[len] : len
  }
  return result
}

const PRIZES = ['RM250', 'RM250', 'RM500', 'RM1000']
const MOCK_USERS = [
  'Ally Henley',
  'Malaki Whitmore',
  'Karlyn Cordle',
  'Shana Schlueter',
  'Maylie Grindstaff',
  'Myasia Esqueda',
  'Drake Ayers',
  'Alder Albin',
  'Zuriel Krohn',
  'Kc Meltzer',
  'Shae Whiteside',
  'Lyric Chase',
  'Britton Blythe',
  'Ashton Salazar',
  'Maleek Reel',
  'Wali Bosco',
  'Cristina Whitt',
  'Zayla Eller',
  'Wylder Paddock',
  'Azeneth Marchand',
]

export default function AdminLuckyDrawPage() {
  const videoRef = useRef(null)

  const [loading, setLoading] = useState(true)
  const [winners, setWinners] = useState([])

  useEffect(() => {
    setWinners(
      getRandom(MOCK_USERS, 4).map((name, index) => ({
        id: index,
        name,
        revealed: false,
        prize: PRIZES[index],
      }))
    )
    setLoading(false)
  }, [])

  // useEffect(() => {
  //   Axios.get(
  //     'https://cloudexpo-server.herokuapp.com/api/v1/list_eligible?token=liren'
  //   )
  //     .then((result) => {
  //       const { eligibles } = result.data
  //       const winners = getRandom(eligibles, 4)
  //       // console.log('======= 4 WINNERS IN ORDER =======')
  //       // console.log(winners)
  //       // console.log('==================================')
  //       setLoading(false)
  //       setWinners(
  //         winners.map((winner, index) => ({
  //           ...winner,
  //           revealed: false,
  //           prize: PRIZES[index],
  //         }))
  //       )
  //     })
  //     .catch((err) => {
  //       console.log(err)
  //       alert('Something went wrong! Contact Liren (019-8891231) asap!')
  //     })
  // }, [])

  const openBox = (e) => {
    videoRef.current.currentTime = '0'
    videoRef.current.play()
    const { id } = e.currentTarget
    setWinners(
      winners.map((winner) => ({
        ...winner,
        revealed: id == winner.id ? true : winner.revealed,
      }))
    )
  }
  console.log(winners)
  return (
    <Page>
      <header>
        <img src={config.assets.logo.landscape} alt="logo" className="logo" />
        <div className="vid-wrapper">
          <video autoPlay ref={videoRef} src={bannerVid}></video>
        </div>
        <div className="title">
          <h2>TREASURE HUNT</h2>
          <h1>LUCKY DRAW WINNERS</h1>
        </div>
      </header>
      <section className="position-relative">
        {loading ? (
          <LoadIcon />
        ) : (
          <Row>
            {winners.map((winner) => (
              <Col xs={6} md={3} key={winner.id}>
                <div
                  id={winner.id}
                  onClick={openBox}
                  className={`box-wrapper ${winner.revealed ? '' : 'unopened'}`}
                >
                  {winner.revealed ? (
                    <>
                      <p className="winner-name">{winner.name}</p>
                      <img src={boxOpen} alt="Winner Box" />
                    </>
                  ) : (
                    <img src={boxClose} alt="Winner Box" />
                  )}
                </div>
                <p className="amount">{winner.prize}</p>
              </Col>
            ))}
          </Row>
        )}
      </section>
    </Page>
  )
}
