const questions = [
  {
    id: 1,
    question:
      'Visanne is significantly more efficacious than COC by reducing the size of ovarian endometrioma by > ___ %?',
    choices: ['50%', '60%', '75%', '80%'],
  },
  {
    id: 2,
    question:
      'When should Boostrix [Diphtheria, tetanus and pertussis (acellular, component) vaccine (absorbed, reduced antigen(s) content)]  be administered during pregnancy?',
    choices: [
      'Between 27 and 36 weeks gestation',
      'Between 16 and 24 weeks gestation',
      'Between 20 and 30 weeks gestation',
      'Between 32 and 36 weeks gestation',
    ],
  },
  {
    id: 3,
    question:
      'Which ultrasound machine brand is committed in creating a healthier future for women?',
  },
  {
    id: 4,
    question:
      'Adacel is the first and only Tdap vaccine in Malaysia to be INDICATED for Passive Protection against Pertussis in early infancy following Maternal Immunization during Pregnancy.',
    choices: ['True', 'False'],
  },
  {
    id: 5,
    question:
      'What is the recommended dose of Duphaston for threatened miscarriage?',
    choices: [
      'Starting dose of 40mg follow by 10 mg TDS until symptom abate',
      'Starting dose of 40mg follow by 10 mg BD until symptom abate',
      '10mg TDS until twentieth week of pregnancy',
      '10mg BD until twentieth week of pregnancy',
    ],
  },
  {
    id: 6,
    question:
      'What is the recommended daily intake of vitamin D according to the Clinical Guidance on Management of Osteoporosis 2012 (revised 2015)?',
    choices: ['800 IU', '700 IU', '600 IU', '500 IU'],
  },
  {
    id: 7,
    question: 'What is the active ingredient of Maltofer®?',
    choices: [
      'Ferrous Sulphate',
      'Iron (III) hydroxide polymaltose complex',
      'Ferrous Fumarate',
      'Ferrous Gluconate',
    ],
  },
  {
    id: 8,
    question:
      'In addition to HPV 16 and 18, HPV types ______ are among the prevalent HPV types in cervical cancer and high-grade cervical lesions in Malaysia and Singapore.',
    choices: ['6 and 11', '52 and 58', '66 and 68'],
  },
  {
    id: 9,
    question:
      'Giving Fraxiparine for perioperative gynaecological surgery patients is effective to prevent DVT.',
    choices: ['True', 'False'],
  },
  {
    id: 10,
    question: "How many cells are usually collected in baby's cord blood?",
    choices: [
      '500 - 800 million cells',
      '100 - 200 million cells',
      '200 - 300 million cells',
      '50 - 80 million cells',
    ],
  },
  {
    id: 11,
    question: 'Which statement is false about GnRH agonist Lucrin®?',
    choices: [
      'It acts on GnRH receptors in the pituitary',
      'It is available as a depot injection, either monthly or 3-monthly',
      'It is administered on a daily basis',
      'It is a prefilled syringe',
    ],
  },
  {
    id: 12,
    question:
      'What is the maximum blood vessel diameter Ligasure vessel sealing can handle?',
    choices: ['3mm', '5mm', '7mm', '10mm'],
  },
  {
    id: 13,
    question:
      'Anmum Materna is the only pre-natal milk that has no added sugars and is low in fat.',
    choices: ['True', 'False'],
  },
  {
    id: 14,
    question: 'What is the tagline for NICC?',
    choices: [
      'Safe for Mommy and Baby',
      'Healthy Baby, Happy Mommy',
      'Safe for Baby, Peace of Mind for Mommy',
      'Peace of mind for Mommy',
    ],
  },
  {
    id: 15,
    question:
      'Obimin Pluz is one of the preferred pre & post-natal supplement because:',
    choices: [
      'It not only contains all the essential nutrients eg: vitamins, minerals and DHA for pregnancy and lactation but also has 15mg of Zinc for Immunity PROTECTION',
      'It contains only iron for pregnancy',
      'It only contains omega-3 fatty acids for pregnancy',
      'It contains only iodine and zinc',
    ],
  },
  {
    id: 16,
    question:
      'Which guideline is Fluomizin recommended as the treatment for bacteria vaginosis?',
    choices: [
      '2018 European International Union against Sexual Transmitted Infection (IUSTI)',
      '2013 European International Union against Sexual Transmitted Infection (IUSTI)',
      '2018 American Union against Sexual Transmitted Infection (IUSTI)',
      '2015 American International Union against Sexual Transmitted Infection (IUSTI)',
    ],
  },
]

export default questions
