import React, { useState } from 'react'
import Axios from 'axios'
import { useSelector } from 'react-redux'
import config from '../config'
import { toast } from 'react-toastify'

export default function GoogleSheetForm({ toggleForm, hallNumber = 1}) {
  const { id, name, email } = useSelector((state) => state.auth.currentUser)
  const [ loading, setLoading ] = useState(false)
  const [question, setQuestion] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    Axios.post(`${config.apiUrl}/api/v1/live_question`, {
      // UserID: id,
      // Name: name,
      // Email: email,
      Question: question,
      HallName: `Hall${hallNumber}`
    }, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('jwt_token')}`
      }
    })
      .then((resp) => {
        toast('We have received your question, thank you!')
        setQuestion('')
        toggleForm()
        setLoading(false)
      })
      .catch((err) => {
        toast('Something went wrong, pls try again!')
        toggleForm()
        setLoading(false)
      })
  }

  const handleInput = (e) => setQuestion(e.target.value)

  return (
    <form onSubmit={handleSubmit}>
      <textarea
        value={question}
        onChange={handleInput}
      ></textarea>
      <input className="btn" type="submit" value={loading ? 'Submitting...' : 'Submit'} disabled={loading || question.length < 5} />
    </form>
  )
}
