import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap'
import styled from 'styled-components'
import { Link, useHistory } from 'react-router-dom'

import { SIZES, COLORS } from '../styles/theme'
import PageWithBg from '../components/PageWithBg'
import LoadIcon from '../components/LoadIcon'
import config from '../config'

const Page = styled.div`
  cursor: pointer;

  #target-a {
    left: 75.9%;
    top: 52%;
  }

  #target-b {
    left: 31%;
    top: 71%;
  }

  .target {
    position: fixed;

    &:hover {
      svg {
        animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
      }
    }

    svg {
      animation: heartbeat 7s infinite;
    }
  }
`
let tawk = {}
export default function BoothPage({ currentUser }) {
  const [modal, setModal] = useState(false)

  useEffect(() => {
    var Tawk_API = window.Tawk_API
    if (Tawk_API) {
      Tawk_API.showWidget()
    }

    var Tawk_LoadStart = new Date()
    ;(function () {
      var s1 = document.createElement('script'),
      s0 = document.getElementsByTagName('script')[0]
      s1.async = true
      s1.src = 'https://embed.tawk.to/5fa44ae7fe038e7c95aa6e4d/default'
      s1.charset = 'UTF-8'
      s1.setAttribute('crossorigin', '*')
      s0.parentNode.insertBefore(s1, s0)
    })()

    return () => {
      window.Tawk_API.hideWidget()
    }
  }, [currentUser.email, currentUser.name])

  const showModal = () => setModal(true)
  const hideModal = () => setModal(false)
  return (
    <Page>
      <PageWithBg avoidNavOverlap bgImg={config.assets.booth}>
        <div className="target" id="target-a" onClick={showModal}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={36}
            height={36}
            viewBox="0 0 24 24"
            fill="none"
            stroke="#ff0000"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <circle cx="12" cy="12" r="10"></circle>
            <line x1="12" y1="16" x2="12" y2="12"></line>
            <line x1="12" y1="8" x2="12.01" y2="8"></line>
          </svg>
        </div>
        <div className="target" id="target-b" onClick={showModal}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={36}
            height={36}
            viewBox="0 0 24 24"
            fill="none"
            stroke="#ff0000"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <circle cx="12" cy="12" r="10"></circle>
            <line x1="12" y1="16" x2="12" y2="12"></line>
            <line x1="12" y1="8" x2="12.01" y2="8"></line>
          </svg>
        </div>
      </PageWithBg>
      <Modal show={modal} onHide={hideModal} centered>
        <Modal.Header closeButton>
          <Modal.Title id="schedule-modal">Booth Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>You can display text, images and videos here</p>
          <p>
            Visitors can also chat/call the booth exhibitor via the button at
            the bottom right of this page
          </p>
          <p>
            If the person-in-charge is not online, visitors can simply leave a
            message!
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button style={{ background: COLORS.blue }} onClick={hideModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Page>
  )
}
