import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import ReactGA from 'react-ga'

import NoticeBar from '../components/NoticeBar'
import PageWithBg from '../components/PageWithBg'
import HallOverlay from '../components/HallOverlay'

import pinkPin from '../images/pin-pink.png'
import { useSelector } from 'react-redux'
import { SIZES } from '../styles/theme'
import config from '../config'

const PageWrapper = styled.div`
  #networking-pin {
    ${config.layout.networkingPin.landscape}
  }
  #auditorium-pin {
    ${config.layout.auditoriumPin.landscape}
  }
  #cloudflix-pin {
    ${config.layout.cloudflixPin.landscape}
  }
  #exhibition-pin {
    ${config.layout.exhibitionPin.landscape}
  }

  ${SIZES.mobile} {
    #networking-pin {
      ${config.layout.networkingPin.portrait}
    }
    #cloudflix-pin {
      ${config.layout.cloudflixPin.portrait}
    }
    #exhibition-pin {
      ${config.layout.exhibitionPin.portrait}
    }
    #auditorium-pin {
      ${config.layout.auditoriumPin.portrait}
    }
  }

  button {
    background-image: url(${pinkPin});
    background-color: transparent;
    border: none;
    z-index: 5;
  }

  .pins {
    cursor: pointer;
    position: absolute;
    width: 7%;
    height: 7%;
    background-image: url(${pinkPin});
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
    animation: heartbeat 7s infinite;

    &:hover {
      animation-name: bounce;
      animation-duration: 1s;
      animation-fill-mode: both;

      .pin-text {
        display: block;
      }
    }

    .pin-text {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 80%;
      white-space: nowrap;
      display: none;

      h5 {
        color: white;
        text-shadow: 1px 1px 1px #000;
      }
    }
  }
`

export default function LobbyPage({ currentUser }) {
  const { muted } = useSelector((state) => state.layout)
  const [overlay, setOverlay] = useState(null)

  const showConferenceOverlay = () => setOverlay('conference')
  const showTourOverlay = () => setOverlay('hall')
  const hideOverlay = () => setOverlay(null)

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      hideOverlay()
    }
  }, [])

  useEffect(() => {
    ReactGA.initialize(config.googleAnalyticsID, {
      gaOptions: {
        userId: currentUser.id,
      },
    })
  }, [currentUser.id])

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false)

    return () => {
      document.removeEventListener('keydown', escFunction, false)
    }
  }, [escFunction])

  return (
    <PageWrapper>
      <NoticeBar />
      <audio
        loop
        autoPlay
        muted={muted}
        style={{ visibility: 'hidden' }}
        src={config.assets.lobbyMusic}
      ></audio>
      <PageWithBg
        bgImg={config.assets.lobby.landscape}
        portraitBg={config.assets.lobby.portrait}
      >
        <HallOverlay overlay={overlay} hideOverlay={hideOverlay} />

        {/* <Link to="/networking" id="networking-pin" className="pins"> */}
        <button id="networking-pin" onClick={() => alert('Under maintenance')} className="pins">
          <div className="pin-text">
            <h5>Enter Networking Lounge</h5>
          </div>
        </button>
        {/* </Link> */}

        {/* Use this if need multi halls */}
        {/* <button id="exhibition-pin" onClick={showTourOverlay} className="pins">
          <div className="pin-text">
            <h5>Enter Exhibition Hall</h5>
          </div>
        </button> */}

        {/* Directly enter hall */}
        <Link to="/exhibition-hall" id="exhibition-pin" className="pins">
          <div className="pin-text">
            <h5>Enter Exhibition Hall</h5>
          </div>
        </Link>

        <button
          id="auditorium-pin"
          onClick={showConferenceOverlay}
          className="pins"
        >
          <div className="pin-text">
            <h5>Enter Auditorium Hall</h5>
          </div>
        </button>

        <Link to="/videos" id="cloudflix-pin" className="pins">
          <div className="pin-text">
            <h5>Enter Video Library</h5>
          </div>
        </Link>

      </PageWithBg>
    </PageWrapper>
  )
}
