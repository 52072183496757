import React from 'react'
import styled from 'styled-components'
import { SIZES } from '../styles/theme'
import { Link } from 'react-router-dom'
import Axios from 'axios'
import { useEffect, useState } from 'react'

const Page = styled.div`
  ${SIZES.contentWithMinHeight(0)}

  background: #f3f3f3;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  #participants {
    height: 400px;
    overflow: scroll;
    border: solid 1px lightgray;
    padding: 10px;
  }
`
export default function AdminPage() {
  const [winners, setWinners] = useState([])
  useEffect(() => {
    Axios.get(
      'https://cloudexpo-server.herokuapp.com/api/v1/list_eligible?token=liren'
    )
      .then((result) => {
        setWinners(result.data.eligibles)
      })
      .catch((err) => {
        console.log(err)
        alert('Something went wrong! Contact Liren (019-8891231) asap!')
      })
  }, [winners])
  return (
    <Page>
      <Link className="font-weight-bold" to="/admin/lucky-draw"><h2>
        Click here to start Lucky Draw
      </h2></Link>
      <section id="participants" className="mt-4">
        <p><strong>List of eligible participants for lucky draw:</strong></p>
        <ol>
          {
            winners.map(w => <li>{w.name}</li>)
          }
        </ol>
      </section>
    </Page>
  )
}
