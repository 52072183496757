import React from 'react'
import { Tabs, Tab } from 'react-bootstrap'
import styled from 'styled-components'

const Wrapper = styled.div`
  /* font-size: .85em; */
`

export default function Programme() {
  return (
    <Wrapper>
      <Tabs defaultActiveKey="day2">
        <Tab eventKey="day1" title="Day 1 - Sept, 5th">
          <table className="table table-borderless table-hover table-responsive">
            <thead>
              <tr>
                <th>Time</th>
                <th>Programme</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>0800 - 1200</td>
                <td>Welcome Speech by CEO</td>
              </tr>
            </tbody>
          </table>
        </Tab>
        <Tab eventKey="day2" title="Day 2 - Sept, 6th">
          <table className="table table-borderless table-hover table-responsive">
            <thead>
              <tr>
                <th>Time</th>
                <th>Programme</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>0800 - 1200</td>
                <td>Welcome Speech by CEO</td>
              </tr>
            </tbody>
          </table>
        </Tab>
      </Tabs>
    </Wrapper>
  )
}
