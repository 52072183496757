import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import ReactGA from 'react-ga'

import { SIZES, COLORS } from '../styles/theme'

import GoogleSheetForm from '../components/GoogleSheetForm'
import PageWithBg from '../components/PageWithBg'
import LoadIcon from '../components/LoadIcon'
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { toggleShowForm } from '../ducks/layout'
import config from '../config'

const Page = styled.div`
  .qna-container {
    position: absolute;
    z-index: 3000;
    left: 50%;
    transform: translate(-50%);

    background: rgba(255, 255, 255, 0.8);
    border-radius: 12px;
    font-size: .85em;

    bottom: 130px;
    ${SIZES.mobile} {
      bottom: 56px;
      font-size: .7em;
    }

    header {
      display: flex;
      background: ${COLORS.primary};
      color: white;
      padding: 15px 5px 15px 20px;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;

      button {
        color: white;
        font-weight: bold;
        border: 0;
        background: none;
        width: 40px;
      }
    }

    form {
      position: relative;
      width: 100%;
      height: 100%;
      max-height: 200px;
      background: white;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;

      textarea {
        min-height: 150px;
        resize: none;
        outline: none;
        border: none;
        background: white;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        padding: 20px;
        height: 100%;
        width: 100%;
      }

      .btn {
        position: absolute;
        font-size: 12px;
        bottom: 10px;
        right: 10px;
        height: 40px;
        color: white;
        background: ${COLORS.primary};
        padding: 5px 15px;
        border-radius: 40px;

        &:hover {
          background: ${COLORS.primaryTrans};
        }
      }
    }
  }

  .content {
    ${config.layout.auditoriumVid}
    position: absolute;
    background: black;

    iframe {
      height: 100%;
      width: 100%;
    }
  }
`

export default function LivePage({ liveUrl, hallBg, hallNumber }) {
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  const { showForm } = useSelector((state) => state.layout)

  const toggleForm = () => dispatch(toggleShowForm())

  useEffect(() => {
    ReactGA.pageview(`/conference-hall-${hallNumber}`)
  }, [hallNumber])

  return (
    <Page>
      <PageWithBg bgImg={hallBg}>
        {showForm && (
          <div className="qna-container">
            <header>
              <div>
                Submit your questions anytime! We will answer your questions
                during Q&A Section
              </div>
              <button onClick={toggleForm}>&#10005;</button>
            </header>
            <GoogleSheetForm toggleForm={toggleForm} hallNumber={hallNumber} />
          </div>
        )}

        <div className="content">
          {loading && <LoadIcon />}
          <iframe
            title="Live Broadcast"
            src={liveUrl}
            allow="autoplay; fullscreen"
            allowFullScreen
            frameBorder="0"
            onLoad={() => {
              setLoading(false)
            }}
          />
        </div>
      </PageWithBg>
    </Page>
  )
}
