import React, { Suspense, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Switch, Route } from 'react-router-dom'

import EntrancePages from './containers/EntrancePages'
import PrivateRoute from './containers/PrivateRoute'
import LobbyPage from './pages/LobbyPage'
import TourPage from './pages/TourPage'
import LivePage from './pages/LivePage'
import CountdownPage from './pages/CountdownPage'
import AdminPage from './pages/AdminPage'
import AdminLuckyDrawPage from './pages/AdminLuckyDrawPage'
import AdminRoute from './containers/AdminRoute'
import ProgrammeModal from './components/ProgrammeModal'
import LoadIcon from './components/LoadIcon'
import config from './config'
import { useEffect } from 'react'
import ExhibitionHallPage from './pages/ExhibitionHallPage'
import BoothPage from './pages/BoothPage'
import CloudflixPage from './pages/CloudflixPage'

const ZoomLivePage = React.lazy(() => import('./pages/ZoomLivePage'))

function App() {
  const { currentUser, isAuthenticated } = useSelector((state) => state.auth)

  useEffect(() => {
    document.title = config.eventName
  }, [])

  return useMemo(() => {
    return (
      <>
        <ProgrammeModal />
        <Switch>

          <Route exact path="/enter" component={EntrancePages} />

          {/* Login */}
          <Route exact path="/login" component={EntrancePages} />

          {/* Enter Event */}
          <Route exact path="/" component={EntrancePages} />

          {/* Lobby Page */}
          <PrivateRoute
            exact
            fixedNav
            currentUser={currentUser}
            authed={isAuthenticated}
            path="/lobby"
            component={LobbyPage}
            navOptions={['schedule', 'qrScan', 'luckydraw', 'audio']}
          />

          {/* Birdeyed View Exhibition Hall */}
          <PrivateRoute
            exact
            fixedNav
            currentUser={currentUser}
            authed={isAuthenticated}
            path="/exhibition-hall"
            navOptions={['lobby', 'schedule']}
            component={ExhibitionHallPage}
          />

          {/* Single Booth Page */}
          <PrivateRoute
            fixedNav
            currentUser={currentUser}
            authed={isAuthenticated}
            path="/exhibition/*"
            navOptions={['back', 'schedule']}
            component={BoothPage}
          />

          {/* Vimeo Hall */}
          <PrivateRoute
            exact
            fixedNav
            currentUser={currentUser}
            authed={isAuthenticated}
            path="/conference-hall-1"
            navOptions={['lobby', 'hall2', 'schedule', 'question']}
            component={(props) => (
              <LivePage
                {...props}
                hallNumber={1}
                hallBg={config.assets.auditorium.landscape}
                liveUrl="https://player.vimeo.com/video/392356764?autoplay=1"
              />
            )}
          />

          {/* Zoom Hall */}
          <PrivateRoute
            exact
            currentUser={currentUser}
            authed={isAuthenticated}
            path="/conference-hall-2"
            navOptions={['lobby']}
            component={(props) => (
              <Suspense
                fallback={
                  <LoadIcon style={{ position: 'fixed', zIndex: 999 }} />
                }
              >
                <ZoomLivePage currentUser={currentUser} />
              </Suspense>
            )}
          />

          {/* CloudFlix */}
          <PrivateRoute
            exact
            currentUser={currentUser}
            authed={isAuthenticated}
            path="/videos"
            component={CloudflixPage}
            navOptions={['lobby']}
          />

          {/* Admin Pages */}
          <AdminRoute
            exact
            currentUser={currentUser}
            authed={isAuthenticated}
            path="/admin"
            component={AdminPage}
          />
          <AdminRoute
            exact
            currentUser={currentUser}
            authed={isAuthenticated}
            path="/admin/lucky-draw"
            component={AdminLuckyDrawPage}
          />
        </Switch>
      </>
    )
  }, [currentUser, isAuthenticated])
}

export default App


/**
 * <PrivateRoute
   exact
   currentUser={currentUser}
   authed={isAuthenticated}
   path="/virtual-exhibition-a"
   navOptions={['lobby', 'tourB']}
   component={(props) => (
     <TourPage
       {...props}
       hallNumber={'A'}
       tourUrl="https://staging.webist.name/HallA"
     />
   )}
 />
 <PrivateRoute
   exact
   currentUser={currentUser}
   authed={isAuthenticated}
   path="/virtual-exhibition-b"
   navOptions={['lobby', 'tourA']}
   component={(props) => (
     <TourPage
       {...props}
       hallNumber={'B'}
       tourUrl="https://staging.webist.name/HallB"
     />
   )}
 />

 <PrivateRoute
   exact
   currentUser={currentUser}
   authed={isAuthenticated}
   path="/recordings"
   component={RecordingPage}
   navOptions={['lobby']}
 />
 <PrivateRoute
   exact
   currentUser={currentUser}
   authed={isAuthenticated}
   path="/recordings/watch"
   component={VimeoPage}
   navOptions={['back']}
 />
 */