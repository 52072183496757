import React from 'react'
import styled from 'styled-components'
import { SIZES } from '../styles/theme'

const Wrapper = styled.div`
  marquee {
    position: fixed;
    ${SIZES.rightBelowTopNav(0)};
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 300;
    color: white;
    font-size: 16px;
    /* font-weight: 700; */
    letter-spacing: 1px;
    padding: 2px 0;

    span {
      margin: 0 0.8em;
    }
  }
`

export default function NoticeBar() {
  return (
    <Wrapper>
      <marquee scrollamount={10}>
        <span className="font-weight-bold">
          This Live Conference has come to an end, however you can still watch
          the recordings in Video Gallery Hall. Thank you for your
          participation!
        </span>
      </marquee>
    </Wrapper>
  )
}
