import React, { useState } from 'react'
import styled from 'styled-components'
import { Row, Col } from 'react-bootstrap'
import ModalVideo from 'react-modal-video'
import ReactGA from 'react-ga'

import { SIZES } from '../styles/theme'

import monitorImg from '../images/cloudflix/monitor.png'

import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import config from '../config'

const Page = styled.div`
  background: rgb(23, 5, 80);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(54, 6, 92, 1) 100%
  );
  min-height: 100vh;
  position: relative;
  padding-top: 3em;
  padding-bottom: 10em;

  .header {
    padding: 1em 0;
    img {
      display: block;
      margin: auto;
    }

    .title {
      color: white;
      text-transform: uppercase;

      h1 {
        font-size: 2.6em;
        color: #c77dff;
        letter-spacing: 1px;

        ${SIZES.mobile} {
          font-size: 1.5em;
        }
      }
    }

    .badge {
      font-size: 2.1em;
      letter-spacing: 1px;
      margin-top: 0.4em;
      font-weight: 100;
      padding: 10px 20px;
      border-radius: 50px;
      color: white;
      background: rgb(255, 169, 140);
      background: linear-gradient(
        270deg,
        rgba(255, 169, 140, 1) 0%,
        rgba(247, 153, 144, 1) 9%,
        rgba(227, 113, 156, 1) 47%,
        rgba(196, 48, 174, 1) 77%,
        rgba(173, 0, 189, 1) 100%
      );

      ${SIZES.mobile} {
        font-size: 1.2em;
      }
    }
  }

  .recordings {
    .cover-placeholder {
      height: 400px;
      width: 100%;
      background: gray;

      &:hover {
        opacity: 0.8;
      }
    }
  }
`

export default function CloudflixPage() {
  const [player, setPlayer] = useState(false)
  const openPlayer = () => setPlayer(true)
  const closePlayer = () => setPlayer(false)

  useEffect(() => {
    ReactGA.pageview('/recordings-page')
  }, [])

  return (
    <Page>
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={player}
        videoId="px9fyXtdQWs"
        onClose={closePlayer}
      />
      <Row noGutters className="header">
        <Col className="d-none d-md-block align-self-center" md={5}>
          <img width="50%" src={config.assets.logo.landscape} alt="headphone" />
        </Col>
        <Col
          xs={{ span: 12, order: 'first' }}
          md={4}
          className="d-flex justify-content-center align-items-center p-0 p-md-3"
        >
          <div className="title mb-5 mb-md-0">
            <h1>EVENT VIDEO</h1>
            <div className="badge d-block mx-auto d-md-inline-block">
              GALLERY
            </div>
          </div>
        </Col>
        <Col md={3} className="d-none d-md-block">
          <img width="80%" src={monitorImg} alt="monitor" />
        </Col>
      </Row>

      <div className="p-2 p-md-4">
        <Row className="recordings" noGutters>
          <Col xs={6} md={4} lg={4} xl={2} className="p-2 p-md-3">
            <a href="#" onClick={openPlayer}>
              <div className="cover-placeholder"></div>
            </a>
          </Col>
          <Col xs={6} md={4} lg={4} xl={2} className="p-2 p-md-3">
            <a href="#" onClick={openPlayer}>
              <div className="cover-placeholder"></div>
            </a>
          </Col>
          <Col xs={6} md={4} lg={4} xl={2} className="p-2 p-md-3">
            <a href="#" onClick={openPlayer}>
              <div className="cover-placeholder"></div>
            </a>
          </Col>
        </Row>
      </div>
    </Page>
  )
}
