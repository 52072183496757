const TOGGLE_MUTE = 'TOGGLE_MUTE'
const TOGGLE_QUESTION_FORM = 'TOGGLE_QUESTION_FORM'

const HIDE_TH_DROPDOWN = 'HIDE_TH_DROPDOWN'
const TOGGLE_TH_DROPDOWN = 'TOGGLE_TH_DROPDOWN'

const HIDE_QR_DROPDOWN = 'HIDE_QR_DROPDOWN'
const TOGGLE_QR_DROPDOWN = 'TOGGLE_QR_DROPDOWN'

const SHOW_SCHEDULE = 'SHOW_SCHEDULE'
const HIDE_SCHEDULE = 'HIDE_SCHEDULE'

const initialState = {
  showForm: false,
  muted: false,
  thDropdown: false,
  qrDropdown: false,
  scheduleModal: false,
}

export default function layoutReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_MUTE:
      return {
        ...state,
        muted: !state.muted,
      }

    case TOGGLE_QUESTION_FORM:
      return {
        ...state,
        showForm: !state.showForm,
      }

    case HIDE_TH_DROPDOWN:
      return {
        ...state,
        thDropdown: false,
      }

    case TOGGLE_TH_DROPDOWN:
      return {
        ...state,
        thDropdown: !state.thDropdown,
        qrDropdown: false,
      }

    case HIDE_QR_DROPDOWN:
      return {
        ...state,
        qrDropdown: false,
      }

    case TOGGLE_QR_DROPDOWN:
      return {
        ...state,
        qrDropdown: !state.qrDropdown,
        thDropdown: false,
      }

    case SHOW_SCHEDULE:
      return {
        ...state,
        scheduleModal: true,
      }

    case HIDE_SCHEDULE:
      return {
        ...state,
        scheduleModal: false,
      }

    default:
      return state
  }
}

export function toggleMute() {
  return { type: TOGGLE_MUTE }
}

export function toggleShowForm() {
  return { type: TOGGLE_QUESTION_FORM }
}

export function hideThDropdown() {
  return { type: HIDE_TH_DROPDOWN }
}

export function toggleThDropdown() {
  return { type: TOGGLE_TH_DROPDOWN }
}

export function hideQrDropdown() {
  return { type: HIDE_QR_DROPDOWN }
}

export function toggleQrDropdown() {
  return { type: TOGGLE_QR_DROPDOWN }
}

export function showSchedule() {
  return { type: SHOW_SCHEDULE }
}
