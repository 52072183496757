const SET_STEP = 'SET_STEP'
const SET_ANSWER = 'SET_ANSWER'

const initialState = {
  quizStep: 1,
  answer: '',
}

export default function quizReducer(state = initialState, action) {
  switch (action.type) {
    case SET_STEP:
      return {
        ...state,
        quizStep: action.step > 0 && action.step < 17 ? action.step : state.quizStep,
        answer: '',
      }

    case SET_ANSWER:
      return {
        ...state,
        answer: action.answer,
      }

    default:
      return state
  }
}

export function setStep(step) {
  return {
    type: SET_STEP,
    step,
  }
}

export function setAnswer(answer) {
  return {
    type: SET_ANSWER,
    answer,
  }
}
