import React, { useState } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { updateQuiz } from '../ducks/auth'
import { setAnswer } from '../ducks/quiz'
import { SIZES } from '../styles/theme'

const Ques = styled.div`
  font-weight: bold;
  font-size: 0.95em;

  .answer {
    display: flex;
    align-items: center;
    margin-bottom: .6em;
    padding-left: .5em;

    label {
      margin: 0;
    }
  }

  button {
    font-size: 0.95em;
  }

  ${SIZES.mobile} {
    font-size: 0.8em;
  }
`

const keys = ['a', 'b', 'c', 'd']

export default function QuizQuestion({ loading, quiz, quizAttempt, answer }) {
  const dispatch = useDispatch()
  const { id, question, choices } = quiz

  const submitAnswer = () => {
    dispatch(updateQuiz(id, answer))
  }

  const inputAnswer = (ans) => {
    dispatch(setAnswer(ans))
  }

  const submitted = quizAttempt[`q${id}`]
  return (
    <Ques>
      <p>
        {id}. {question}
      </p>
      {choices && choices.length ? (
        <>
          {choices.map((choice, index) => {
            const letterChoice = keys[index]
            return (
              <div className="answer" key={letterChoice}>
                <input
                  id={letterChoice}
                  disabled={submitted}
                  className="mr-3"
                  type="radio"
                  name={question}
                  checked={
                    submitted
                      ? submitted === letterChoice
                      : answer === letterChoice
                  }
                  value={letterChoice}
                  onChange={() => {
                    inputAnswer(letterChoice)
                  }}
                />
                <label htmlFor={letterChoice}>
                  {letterChoice.toUpperCase()}. {choice}
                </label>
                <br />
              </div>
            )
          })}
        </>
      ) : (
        <input
          disabled={submitted}
          value={submitted ? submitted : answer}
          onChange={(e) => {
            inputAnswer(e.target.value)
          }}
          type="text"
        />
      )}
      {!submitted && (
        <button
          disabled={submitted || loading || answer.length === 0}
          onClick={submitAnswer}
          className="d-block btn theme-btn mt-4"
        >
          {loading ? 'Submitting...' : 'Submit'}
        </button>
      )}
    </Ques>
  )
}
