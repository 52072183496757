import { lighten, darken, transparentize, adjustHue } from 'polished'

const _PRIMARY = '#0C3C60'
const _NAV = 'rgba(8, 20, 35, 0.93)'

const COLORS = {
  nav: _NAV,

  primary: _PRIMARY,
  primaryDark: darken(0.2, _PRIMARY),
  primaryComp: adjustHue(40, _PRIMARY),
  primaryTrans: transparentize(.2, _PRIMARY),

  blue: '#031E88',
  purple: '#3A2D86',

  lightGray: '#F2F2F2',
  lightGray1: '#B1B1B1',

  text: '#404040',

  pink: '#d1586a',
  darkPink: '#F5216A',

  cyan: '#26B4A9',
}

const FONTS = {
  primary: "'Montserrat', sans-serif;",
  secondary: "'Montserrat', sans-serif;",
}


/**
 * NAVBAR, FLOATING NAV, MOBILE THRESHOLD
 */


const _MOBILE_BP = '567px'
const _TOPNAV_HEIGHT = '65px'

const _FLOATNAV_HEIGHT = {
  landscape: '60px',
  portrait: '40px',
}

const _FLOATNAV_BOTTOM = {
  landscape: '63px',
  portrait: '10px',
}

const SIZES = {
  mobile: `@media (max-width: ${_MOBILE_BP})`,

  topNav: `height: ${_TOPNAV_HEIGHT};`,

  floatNav: `
    height: ${_FLOATNAV_HEIGHT.landscape};
    bottom: ${_FLOATNAV_BOTTOM.landscape};

    @media (max-width: ${_MOBILE_BP}) {
      height: ${_FLOATNAV_HEIGHT.portrait};
      bottom: ${_FLOATNAV_BOTTOM.portrait};
      padding: 5px 16px 5px 16px;
    }
  `,

  rightBelowTopNav: (offset) => `
      top: calc(${_TOPNAV_HEIGHT} + ${offset}px);
    `,

  rightAboveFloatNav: (offset) => `
      bottom: calc(${_FLOATNAV_HEIGHT.landscape} + ${_FLOATNAV_BOTTOM.landscape} + ${offset}px);
      left: 50%;
      transform: translateX(-50%);

      @media (max-width: ${_MOBILE_BP}) {
        bottom: calc(${_FLOATNAV_HEIGHT.portrait} + ${_FLOATNAV_BOTTOM.portrait} + ${offset}px);
      }
    `,

  contentWithMinHeight: function (offset) {
    return `
      min-height: calc(100vh - ${_TOPNAV_HEIGHT} - ${offset}px);
    `
  },

  contentWithFixedHeight: function (offset) {
    return `
      height: calc(100vh - ${_TOPNAV_HEIGHT} - ${offset}px);
    `
  },
}

export { COLORS, FONTS, SIZES }
