import React from 'react'
import styled from 'styled-components'
import { COLORS } from '../styles/theme'

const Wrapper = styled.div`
  cursor: pointer;
  border: solid 1.5px white;
  color: ${(props) => (props.active ? COLORS.purple : 'white')};
  background: ${(props) => (props.active ? 'white' : 'transparent')};
  margin: 3px 6px;
  height: 23px;
  width: 23px;
  border-radius: 50%;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    height: 60%;
    fill: ${(props) => (props.active ? COLORS.purple : 'white')};
  }

  &:hover {
    svg {
      fill: ${COLORS.purple};
    }
    background: white;
    color: ${COLORS.purple};
  }
`

export default function QuizStep({
  step,
  quizAttempt = {},
  active = false,
  goToStep,
}) {
  const submitted = quizAttempt[`q${step}`]
  return (
    <Wrapper active={active} onClick={() => goToStep(step)}>
      {submitted ? (
        <svg x="0px" y="0px" viewBox="0 0 512 512">
          <g>
            <g>
              <path
                d="M504.502,75.496c-9.997-9.998-26.205-9.998-36.204,0L161.594,382.203L43.702,264.311c-9.997-9.998-26.205-9.997-36.204,0
          c-9.998,9.997-9.998,26.205,0,36.203l135.994,135.992c9.994,9.997,26.214,9.99,36.204,0L504.502,111.7
          C514.5,101.703,514.499,85.494,504.502,75.496z"
              />
            </g>
          </g>
        </svg>
      ) : (
        <span>{step}</span>
      )}
    </Wrapper>
  )
}
