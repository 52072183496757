import Logo from './images/aavii-logo.webp'
import Countdown from './images/backdrops/countdown.jpg'
import Lobby from './images/backdrops/lobby.jpg'
import Auditorium from './images/backdrops/auditorium.jpg'
import Exhibition from './images/backdrops/exhibition.jpg'
import Entrance from './images/backdrops/entrance.jpg'
import LobbyMusic from './images/audio/lobby.mp3'

import Booth from './images/backdrops/booth.jpg'

const dev = {
  apiUrl: 'http://localhost:3000',
}

const prod = {
  apiUrl: 'https://cloudexpo-apea.herokuapp.com/',
}

const config = process.env.REACT_APP_STAGE === 'production' ? prod : dev

const assets = {
  logo: {
    landscape: Logo,
    portrait: Logo,
  },

  countdown: Countdown,
  entrance: Entrance,
  booth: Booth,

  lobby: {
    landscape: Lobby,
    portrait: Lobby,
  },
  auditorium: {
    landscape: Auditorium,
    portrait: Auditorium,
  },
  exhibitionHall: {
    landscape: Exhibition,
    portrait: Exhibition,
  },

  lobbyMusic: LobbyMusic,
}

const layout = {
  networkingPin: {
    landscape: `
      top: 46.4%;
      left: 18%;
    `,
    portrait: `
      top: 46.4%;
      left: 18%;
    `,
  },
  auditoriumPin: {
    landscape: `
      top: 45.5%;
      left: 31.5%;
    `,
    portrait: `
      top: 45.5%;
      left: 31.5%;
    `,
  },
  cloudflixPin: {
    landscape: `
      top: 45.5%;
      left: 62%;
    `,
    portrait: `
      top: 45.5%;
      left: 62%;
    `,
  },
  exhibitionPin: {
    landscape: `
      top: 46.4%;
      left: 75.6%;
    `,
    portrait: `
      top: 46.4%;
      left: 75.6%;
    `,
  },

  auditoriumVid: `
    left: 20.7%;
    right: 20.6%;
    top: 8.9%;
    bottom: 38.7%;
  `
}

export default {
  assets,
  layout,

  eventName: 'Aavii Virtual Event',
  googleAnalyticsID: '',
  gsheetQuestionApi:
    'https://script.google.com/macros/s/AKfycbzWqe4JgaKmJ2ukTwG0V-vp5TBCg2VaPeTIhQTWjRDCaUogdzI/exec',

    ...config,
}
