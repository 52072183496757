import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import config from '../config'
import { COLORS, SIZES } from '../styles/theme'

const Page = styled.div`
  background: url(${config.assets.entrance}) no-repeat center center fixed;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  overflow: hidden;

  #enter-btn {
    position: fixed;
    bottom: 80px;
    right: 80px;

    color: #fff;
    font-weight: 300;
    letter-spacing: 1px;

    background-color: ${COLORS.primary};
    padding: 15px 20px;
    font-size: 20px;
    border: 1px solid ${COLORS.primaryDark};

    border-radius: 5px;
    text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 6px 0px ${COLORS.primaryDark}, 0px 3px 15px rgba(0, 0, 0, 0.4),
      inset 0px 1px 0px rgba(255, 255, 255, 0.3),
      inset 0px 0px 3px rgba(255, 255, 255, 0.5);

    transition: all 0.2s ease-in-out;

    &:active {
      box-shadow: 0px 2px 0px ${COLORS.primaryDark}, 0px 1px 6px rgba(0, 0, 0, 0.4),
        inset 0px 1px 0px rgba(255, 255, 255, 0.3),
        inset 0px 0px 3px rgba(255, 255, 255, 0.5);
    }
  }

  ${SIZES.mobile} {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 10%;

    #enter-btn {
      position: static;
    }
  }
`

export default function WelcomePage() {
  return (
    <Page>
      <Link id="enter-btn" to="/login" className="btn">
        ENTER EVENT
      </Link>
    </Page>
  )
}
