import React from 'react'
import Avatar from 'react-avatar'
import styled from 'styled-components'
import { Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import TreasureHunt from './TreasureHunt'
import Qr from './Qr'
import { logoutUser } from '../ducks/auth'
import { SIZES, COLORS } from '../styles/theme'
import config from '../config'

const StyledNavbar = styled(Navbar)`
  ${SIZES.topNav};
  background: ${COLORS.nav};

  .navbar-brand {
    height: 100%;
    padding: 0;

    .logo {
      &.big {
        height: 100%;
        display: block;
      }
    }
  }

  .navbar-nav {
    align-items: center;

    .nav-link {
      color: white;
    }
  }
`

export default function TopNav({ currentUser, fixedNav }) {
  const dispatch = useDispatch()

  const handleMyProfile = () => {
    alert('Still under development! (This page will eventually lead user to Profile page where they can edit personal info)')
  }

  const handleNtfClick = () => {
    alert('Still under development! (This will open a notification box)')
  }

  const handleLogout = () => {
    dispatch(logoutUser())
  }

  return (
    <>
      <TreasureHunt />
      <Qr showQr />
      <StyledNavbar {...(fixedNav ? { fixed: 'top' } : {})}>
        <Navbar.Brand as={Link} to="/lobby">
          <img className="logo big" src={config.assets.logo.landscape} alt="Logo" />
        </Navbar.Brand>

        <Nav className="ml-auto h-100">
          <Nav.Link href="#notifications" onClick={handleNtfClick}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#ffffff"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M22 17H2a3 3 0 0 0 3-3V9a7 7 0 0 1 14 0v5a3 3 0 0 0 3 3zm-8.27 4a2 2 0 0 1-3.46 0"></path>
            </svg>
          </Nav.Link>
          <NavDropdown
            alignRight
            title={<Avatar round name={currentUser.name} size="40px" />}
          >
            <NavDropdown.Item href="#profile" onClick={handleMyProfile}>My Profile</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="#" onClick={handleLogout}>Sign Out</NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </StyledNavbar>
    </>
  )
}
