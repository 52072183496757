import React, { useState } from 'react'
import styled from 'styled-components'
import { Link, useHistory } from 'react-router-dom'

import { SIZES, COLORS } from '../styles/theme'

import PageWithBg from '../components/PageWithBg'
import LoadIcon from '../components/LoadIcon'
import { useEffect } from 'react'
import config from '../config'

const Page = styled.div`
  cursor: pointer;
`

export default function ExhibitionHallPage() {
  const history = useHistory()

  const handleBoothClick = () => {
    console.log("!!")
    history.push('/exhibition/sample-booth')
  }

  return (
    <Page onClick={handleBoothClick}>
      <PageWithBg  bgImg={config.assets.exhibitionHall.landscape}>
      </PageWithBg>
    </Page>
  )
}
