import React from 'react'
import styled from 'styled-components'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { hideQrDropdown, showSchedule, toggleMute, toggleQrDropdown, toggleShowForm } from '../ducks/layout'
import { COLORS, SIZES } from '../styles/theme'

// import lobbyIcon from '../images/navigation/lobby.png'
import liveIcon from '../images/navigation/live.svg'
import boothIcon from '../images/navigation/booth.svg'
import backIcon from '../images/navigation/back.svg'
import listIcon from '../images/navigation/list.svg'
import scheduleIcon from '../images/navigation/schedule.png'
import questionIcon from '../images/navigation/question.png'
import treasureIcon from '../images/navigation/treasure.png'
import muteIcon from '../images/navigation/mute.png'
import unmuteIcon from '../images/navigation/unmute.png'
import qrPng from '../images/navigation/qr.png'

const Nav = styled.nav`
  ${SIZES.floatNav};

  position: fixed;
  left: 50%;
  transform: translate(-50%);

  background: rgba(255, 255, 255, 0.5);
  border-radius: 80px;
  padding: 8px 20px 8px 20px;

  display: flex;
  justify-content: space-around;
  align-items: center;

  a,
  button {
    border: none;
    background: none;
    min-width: 60px;
    transition: 0.15s linear;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    text-align: center;
    text-decoration: none;
    margin: 0 5px;

    span {
      font-size: 10px;
      font-weight: 700;
      color: ${COLORS.blue};
      margin-top: 2px;

      &.text-important {
        color: ${COLORS.primaryDark};
      }

      &.text-gray {
        color: ${COLORS.text};
      }
    }

    img {
      height: 75%;
    }

    &:hover {
      transform: scale(1.1);
    }

    ${SIZES.mobile} {
      min-width: 40px;
      img {
        height: 70%;
      }
      span {
        font-size: 6px;
      }
    }
  }
`

export default function FloatingNav({ navOptions = [] }) {
  const history = useHistory()
  const dispatch = useDispatch()
  const { muted } = useSelector((state) => state.layout)

  const displaySchedule = () => {
    dispatch(showSchedule())
  }

  const toggleAudio = () => {
    dispatch(toggleMute())
  }

  const toggleQuestionForm = () => {
    dispatch(toggleShowForm())
  }

  const toggleQrScan = () => {
    dispatch(toggleQrDropdown())
  }

  const options = {
    lobby: (
      <Link to="/lobby" key="lobby">
        <img src={backIcon} alt="Nav" />
        <span>Back to Lobby</span>
      </Link>
    ),

    hall1: (
      <Link to="/conference-hall-1" key="hall1">
        <img src={liveIcon} alt="Nav" />
        <span>Vimeo Hall</span>
      </Link>
    ),

    hall2: (
      <Link to="/conference-hall-2" key="hall2">
        <img src={liveIcon} alt="Nav" />
        <span>Zoom Hall</span>
      </Link>
    ),

    tourA: (
      <Link to="/virtual-exhibition-a" key="tourA">
        <img src={boothIcon} alt="Nav" />
        <span>Exhibition A</span>
      </Link>
    ),

    tourB: (
      <Link to="/virtual-exhibition-b" key="tourB">
        <img src={boothIcon} alt="Nav" />
        <span>Exhibition B</span>
      </Link>
    ),

    schedule: (
      <Link onClick={displaySchedule} to="#" key="schedule">
        <img src={scheduleIcon} alt="Nav" />
        <span>Programme</span>
      </Link>
    ),

    audio: (
      <button onClick={toggleAudio} key="audio">
        {muted ? (
          <img src={unmuteIcon} alt="Nav" />
        ) : (
          <img src={muteIcon} alt="Nav" />
        )}
        <span className="text-gray">{muted ? 'Unmute' : 'Mute'}</span>
      </button>
    ),

    question: (
      <button onClick={toggleQuestionForm} key="question">
        <img src={questionIcon} alt="Nav" />
        <span className="text-important">Ask Question</span>
      </button>
    ),

    back: (
      <button onClick={() => history.goBack()} key="back">
        <img src={listIcon} alt="Nav" />
        <span>Go Back</span>
      </button>
    ),

    luckydraw: (
      <Link to="/admin/lucky-draw" key="luckydraw">
        <img src={treasureIcon} alt="Nav" />
        <span>Lucky Draw</span>
      </Link>
    ),

    qrScan: (
      <button onClick={toggleQrScan} key="qr-scan">
        <img src={qrPng} alt="Nav" />
        <span>CPD QR</span>
      </button>
    )
  }

  if (!navOptions.length) return null

  return <Nav>{navOptions.map((opt) => options[opt])}</Nav>
}
