import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import * as reducers from './ducks/index';

const rootReducer = combineReducers(reducers);

export default function configureStore(initialState={}) {
 return createStore(
   rootReducer,
   initialState,
   composeWithDevTools(
    applyMiddleware(thunk)
   )
 );
}